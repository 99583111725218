@import '../../../assets/scss/common';

.SizeLarge {
 font-size: 3rem;
}

.SizeMedium {
  font-size: 2rem;
}

.SizeSmall {
  font-size: 1rem;
}

.ColorContent {
  color: $content;
}

.ColorPrimary {
  color: $primary;
}

.Center {
  margin: 1rem;
  text-align: center;
}
