@import "../../../../../assets/scss/common";

.Container {
  position: relative;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid $gray-3;
  padding: 0.5rem;
  border-radius: 0.15rem;
  background: $gray-1;

  .Created {
    background: $primary;
    padding: 0.25rem;
    font-size: 0.75rem;
    color: $white;
    width: max-content;
    border-radius: 0.25rem;
  }

  .JobApplicationComment {
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-line;
  }

  .DeleteButton {
    position: absolute;
    right: 0.5rem;
    top: 0.25rem;
  }
}
