@import "../../../assets/scss/common";

.Container {
  position: relative;
  max-width: 20rem;
  width: 100%;
  align-self: center;
}

.SearchResult {
  position: absolute;
  background: #fff;
  z-index: 9999;
  max-height: 20rem;
  overflow-y: auto;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  box-shadow: $box-shadow;
  width: 100%;
}

.SearchResultSectionTitle {
  padding: 0.25rem;
  margin: 0.25rem 0;
  display: block;
}

.SearchResultSectionItem {
  padding: 0.125rem 0.25rem;
  cursor: pointer;
  &:hover {
    background: $gray-3;
  }
}
