@import '../../../assets/scss/common';

.Container {
  background: $content;
  padding: 1rem;
  margin: 1rem;
  border-radius: 4px;
  width: calc(100% - 2rem);
  box-shadow: $box-shadow;
  align-self: center;

  @include for-desktop-up {
    width: 75%
  }
}
