@import "../../assets/scss/common";

.Title {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@include for-phone-only {
  .Title {
    flex-direction: column;
  }
}
