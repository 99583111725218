@import "../../../assets/scss/common";

.LatestSearchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex-direction: row;
  @include for-phone-only {
    flex-direction: column;
  }
}
