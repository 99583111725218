@import "./assets/scss/common";
@import "../node_modules/normalize.css/normalize.css";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "gotham-book", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background;
}
