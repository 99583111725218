$primary: #008000;
$secondary: #eff4f8;
$tertiary: #4BDF4D;

/*
$gray-100: #eeeeee;
$gray-200: #fafafa;
$gray-300: #bdbdbd;
*/

$gray-1: #eeeeee;
$gray-2: #fafafa;
$gray-3: #bdbdbd;

$white: #FFFFFF;
$black: #000000;
$gold: #FFD700;

$text-color: #222832;
$text-color-2: #333333;

$header: $secondary;
$header-link-active: $text-color;
$header-link: $text-color-2;


$border-color: $gray-3;
$border-radius: 3px;

$font-size-xs: 0.7rem;
$font-size-sm: 0.875rem;
$font-size-md: 0.925rem;
$font-size-label: 0.8125rem;

$mobile-width: 768px;
$laptop-width: 1024px;

$success: #8fbb30;
$danger: #bb4c30;
$warning: #f0c425;

$content: $white;
$background: $secondary;
$dark-gray: $gray-3;
$light-gray: $gray-2;

$table-hover: $gray-1;

$input-disabled-bg: $gray-3;
$input-border: 1px solid $dark-gray;
$input-vertical-padding: 0.375rem;
$input-horizontal-padding: 0.25rem;
$input-line-height: 1.5;
$input-border-radius: 4px;

$box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

@mixin heading {
  color: $primary;
  text-transform: uppercase;
  margin: 0 0 1rem 0;
}

@mixin remove-icon {
  position: absolute;
  top: -0.25rem;
  right: -0.5rem;
  color: #fff;
  background: $primary;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-desktop-down {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
