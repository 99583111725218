@import "../../../assets/scss/common";

.Menu {
  display: none;
}

@include for-desktop-up {
  &.DesktopMenu {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  &.MobileHeader {
    display: none;
  }

  .MenuItemContainer {
    max-width: 10rem;
  }

  .UserDropdownContainer {
    margin-left: auto;
  }
}

@include for-desktop-down {
  .MobileMenu {
    display: block;
    position: absolute;
    height: 100vh;
    width: 90%;
    background: $white;
    max-width: 400px;
    z-index: 101;
    transform: translateX(calc(-400px - 2rem));
    transition: transform linear 0.3s;

    &.isOpen {
      transform: translateX(0);
    }

    .MenuItemContainer {
      &:hover {
        .Dropdown {
          position: relative;
        }
      }
    }
  }

  .MobileHeader {
    display: flex;
    align-items: center;
  }

  .Toggle {
    font-size: 2rem;
    color: $primary;
    cursor: pointer;
    margin: 0 0.5rem 0 1rem;
  }
}

@mixin menuItemActiveOrHover {
  color: $header-link-active;
  font-weight: bold;
}

.MenuItemContainer {
  position: relative;
  flex-grow: 1;

  &:hover {
    .Dropdown {
      opacity: 1;
      z-index: 999;
    }

    .MenuIcon {
      transform: rotate(180deg);
    }
  }
}

.MenuItem {
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
  text-decoration: none;
  color: $header-link;
  text-align: center;

  > .MenuIcon {
    margin: 0 0 0 0.5rem;
    font-size: 0.75rem;
    transition: transform 0.3s;
  }

  &:hover {
    @include menuItemActiveOrHover;
  }
}

.MenuItemActive {
  @include menuItemActiveOrHover;
}

.Dropdown {
  opacity: 0;
  position: absolute;
  width: 100%;
  background: $content;
  border: 1px solid $light-gray;
  z-index: -1;
  transition: 0.3s;
}

.UserDropdown {
  color: $header-link;

  &:hover {
    color: $header-link-active;
  }
}

.Search {
  max-width: 25rem;
  div {
    text-align: left;
  }
}
