@import "../../../assets/scss/common";

.Container {
  display: flex;
  margin: 0 -0.5rem;

  @include for-phone-only {
    flex-direction: column;
    gap: 0.25rem;
  }

  @mixin spacing {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  & > div {
    @include spacing;
    flex-grow: 1;
    flex-basis: 0;
  }

  & > button {
    @include spacing;
    align-self: center;
  }
}
