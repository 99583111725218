@import '../../assets/scss/common';

.Container {
  width: 100%;
  height: 10rem;
  border: 0.125rem dashed $gray-3;
  border-radius: .125rem;
  background-color: $gray-2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $primary;
  text-align: center;
  padding: 1rem;
}

@keyframes animateIcon {
  from {
    transform: translateY(0rem);
  }

  to {
    transform: translateY(1rem);
  }
  
}

.Icon {
  font-size: 2rem;

  &.Animate {
    animation-duration: 1s;
    animation-name: animateIcon;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
}
